import React from "react";

export default class MainIcon extends React.Component<any, any> {
    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                version="1.1"
                viewBox="0 0 87.604 70.464"
                className={"sidebar-header-icon"}
            >
                <g transform="translate(-21.21 -34.982) translate(-250.62 -115.14) scale(.26458)">
                    <g fill="#e6e7c8">
                        <ellipse cx="1205.2" cy="775.5" rx="147.3" ry="26.7"/>
                        <ellipse cx="1062.1" cy="823" rx="34.7" ry="10.7"/>
                        <ellipse cx="1338.7" cy="806.9" rx="19.8" ry="5.4"/>
                    </g>
                    <g>
                        <g fill="#437c49">
                            <path d="M1170.3 572.7s9.5-6.6 18.2-5.1c8.7 1.4 13.9 13.8 13.9 13.8z"/>
                            <path d="M1205.3 585.1s10.2-2.9 19.7 1.4c9.5 4.4 5.9 24.8 5.9 24.8z"/>
                            <path d="M1230.8 611.3s11.7.7 16.8 5.1 1.5 22.6-.7 22.6-16.1-27.7-16.1-27.7z"/>
                            <path d="M1241.4 646.2s11.7.7 16.8 5.1 1.5 22.6-.7 22.6-16.1-27.7-16.1-27.7z"/>
                            <path d="M1253 681.5s11.7.7 16.8 5.1 1.5 22.6-.7 22.6c-2.3 0-16.1-27.7-16.1-27.7z"/>
                            <path d="M1269.6 713.4s11.7.7 16.8 5.1 1.5 22.6-.7 22.6-16.1-27.7-16.1-27.7z"/>
                            <path d="M1284.7 751s10.7-4.8 17.2-3.3 11.8 19.3 9.8 20.4c-1.9 1-27-17.1-27-17.1z"/>
                        </g>
                        <path
                            fill="#29c400"
                            fillOpacity="1"
                            d="M1116.5 672.2s-13.1 26.7-15.5 37.9-14.6 16.1-8.7 26.7c5.8 10.7 19 10.7 25.8 3.4 0 0 7.3 0 7.8-8.3s-3.9-9.7-3.9-9.7-2-18 1.4-30.6c3.3-12.6 2.3-22.3-6.9-19.4z"
                        />
                        <path
                            fill="#29c400"
                            fillOpacity="1"
                            d="M1121.3 669.3s-20.9 36.5-14 64.2l6.8 27.7s-6.3 1-16 2.9c-9.7 2-17.5 8.8-10.2 12.2s49.1 4.8 54.9 4.3 1.4-12.6 1.4-12.6l29.7.5s-20.4 11.7-5.3 17 46.7 2.9 53.5-.1c6.8-2.9-9.7-11.2-8.3-13.1 1.5-1.9 91.4 3.8 107.4 3.8s-9.7-9.2-25.8-21.4c-16.1-12.1-39.5-91.3-48.5-104s-125.6 18.6-125.6 18.6z"
                        />
                        <path
                            fill="#d6e04b"
                            d="M1186.8 704.2c-8.8-16.3-11.6-36.6-11.6-36.6s-3.7 0-20.7 2.7c-14.2 2.3-30.2 2.4-35.1 2.4-4.9 9.8-17.7 38.1-12.2 60.7 4.1 16.6 5.7 23.2 6.4 25.9 12.2-1 58.7 8.5 72.5-1.5 14.7-10.5 9.6-37.3.7-53.6z"
                        />
                        <path
                            fill="#b8d534"
                            d="M1173.1 690.7c-7.4-7-20.3-11.6-35-11.6-8.8 0-16.9 1.7-23.6 4.5-.2.5-.4 1.1-.6 1.6 7-2.6 15.3-4.1 24.3-4.1 14.1.1 26.7 3.8 34.9 9.6zm-65.9 17.3c0 .2-.1.5-.1.7 8-4.4 19-7.2 31.1-7.2 14.2 0 26.8 3.7 35 9.5-7.4-7-20.3-11.6-35-11.6-12.4.1-23.4 3.4-31 8.6zm3.3 38.7c.1.3.2.6.2 1 7.6-3.3 17-5.4 27.4-5.4 14.2 0 26.8 3.7 35 9.5-7.4-7-20.3-11.6-35-11.6-10.6 0-20.2 2.5-27.6 6.5zm-4.1-17.8c0 .2 0 .4.1.5 8.1-4.6 19.3-7.5 31.7-7.5 14.2 0 26.8 3.7 35 9.5-7.4-7-20.3-11.6-35-11.6-12.8.1-24.2 3.6-31.8 9.1z"
                        />
                        <path
                            fill="#29c400"
                            fillOpacity="1"
                            d="M1221.3 590.9s-37.2-21.8-51.1-21.8-20.4 14.6-20.4 14.6-8-11.7-17.5-10.2-9.4 39.4-9.4 39.4-25.5-8-40.8-1.4-18.9 43 5.9 51.8c24.8 8.7 37.2 10.9 37.2 10.9l86-11.8s33.5-.8 37.9-14.6c4.4-13.9-2.9-14.6-2.9-14.6s-5.2-25.6-24.9-42.3z"
                        />
                        <path
                            fill="#29c400"
                            fillOpacity="1"
                            d="M1128 582.4c-1.2-.2-9.7.7-12.3 10.4s3.3 20.8 3.3 20.8l6.1.7z"
                        />
                        <path
                            fill="#fff"
                            d="M1174.6 659.1s-4.1-5.4-7.5-5.4-4.4 8.8-4.4 8.8z"
                        />
                        <g fill="#437c49">
                            <path d="M1109 621.2s6.8-5.4 7.8-1.7c1.1 3.7-2 5.1-7.8 1.7z"/>
                            <path d="M1089.9 619.5s-6.8-5.4-7.8-1.7 2 5.1 7.8 1.7z"/>
                            <path d="M1125 671h8.1c.1 0-2.3 4.8-8.1 0z"/>
                        </g>
                        <g fill="#d6e04b">
                            <path d="M1090.2 770.8s5.1 5.4 1 6.4-9.2-1-11.9-.7c-2.7.4.7-6.7 10.9-5.7z"/>
                            <path d="M1176 785s-1.4-6.8-5.8-6.1-5.4 6.8-4.7 8.8 9.2 2.7 10.5-2.7z"/>
                            <path d="M1184.5 787.7s.3-8.8 6.1-8.5 5.8 7.8 3.4 10.5c-2.3 2.7-7.8 1.7-9.5-2z"/>
                            <path d="M1216.1 789.7c4.4-1.2-.7-9.8-4.4-9.2-3.7.7-5.8 6.8-5.8 6.8s5.1 3.7 10.2 2.4z"/>
                        </g>
                        <path
                            fill="#f2ed78"
                            d="M1172.1 689.7c-7.4-7-20.3-11.6-35-11.6-8.1 0-15.5 1.4-21.9 3.9-.2.6-.5 1.1-.7 1.7 6.6-2.2 14.3-3.5 22.6-3.5 14.2 0 26.8 3.7 35 9.5zm-64.5 16.4c-.1.3-.1.6-.2 1 7.9-4 18.3-6.5 29.8-6.5 14.2 0 26.8 3.7 35 9.5-7.4-7-20.3-11.6-35-11.6-11.7 0-22.1 2.9-29.6 7.6zm-1.3 21.2c0 .2 0 .4.1.7 8-4.3 18.8-7 30.8-7.1 14.2 0 26.8 3.7 35 9.5-7.4-7-20.3-11.6-35-11.6-12.4.1-23.4 3.3-30.9 8.5zm3.9 18c.1.3.2.7.2 1 7.4-3.2 16.7-5.1 26.7-5.1 14.2 0 26.8 3.7 35 9.5-7.4-7-20.3-11.6-35-11.6-10.3.1-19.6 2.4-26.9 6.2z"
                        />
                        <path
                            fill="#437c49"
                            d="M1224.9 720.4s-2.2 3.6-1.7 10c.5 6.3 4.4 7 4.4 7l.2-6.6s1.2 4.4 2.2 6.1 9 5.8 17.5 2.9 6.3-12.6 3.4-17-5.4-8.7-5.4-8.7-4.9-18.2-8.5-27.2c-3.7-9-8.3-14.3-8.3-14.3s-2.4-1.2-4.9 0c-2.4 1.2 2.4-3.2 6.6-1.9 4.1 1.2 19 42 19 42s13.4 11.4 8.3 23.6-11.9 10.7-18.9 9.5-9.5-5.6-9.5-5.6-8.3 1-9.2-8c-1-9.1 4.8-11.8 4.8-11.8z"
                        />
                        <g fill="#b2ce3d">
                            <path
                                d="M1209.7 632.7c0 1.7-2.2 3.1-4.9 3.1s-4.9-1.4-4.9-3.1 2.2-3.1 4.9-3.1 4.9 1.3 4.9 3.1z"/>
                            <ellipse cx="1195.7" cy="637" rx="3.2" ry="2"/>
                            <ellipse cx="1206.1" cy="639.4" rx="3.2" ry="2"/>
                            <ellipse
                                cx="1205.8"
                                cy="681"
                                rx="4.9"
                                ry="3.1"
                                transform="translate(-.79 1.4)"
                            />
                            <path
                                d="M1199.9 685.4c0 1.1-1.4 2.1-3.2 2.1s-3.2-.9-3.2-2 1.4-2.1 3.2-2.1c1.8-.1 3.2.8 3.2 2z"/>
                            <ellipse cx="1207.1" cy="687.7" rx="3.2" ry="2"/>
                            <ellipse cx="1208.4" cy="750.7" rx="3.2" ry="2"/>
                            <ellipse cx="1230.9" cy="754" rx="3.2" ry="2"/>
                            <ellipse cx="1216.5" cy="685.3" rx="1.9" ry="1.2"/>
                            <ellipse
                                cx="1271"
                                cy="745.1"
                                rx="4.9"
                                ry="3.1"
                                transform="translate(-.862 1.473)"
                            />
                            <ellipse cx="1261.9" cy="749.5" rx="3.2" ry="2"/>
                            <ellipse cx="1272.3" cy="751.8" rx="3.2" ry="2"/>
                            <ellipse cx="1281.7" cy="749.4" rx="1.9" ry="1.2"/>
                            <ellipse
                                cx="1109.1"
                                cy="767.1"
                                rx="4.9"
                                ry="3.1"
                                transform="translate(-.797 1.153)"
                            />
                            <ellipse cx="1100.1" cy="771.4" rx="3.2" ry="2"/>
                            <path d="M1113.7 773.8c0 1.1-1.4 2.1-3.2 2.1s-3.2-.9-3.2-2 1.4-2.1 3.2-2.1 3.2.9 3.2 2z"/>
                            <ellipse cx="1119.8" cy="771.4" rx="1.9" ry="1.2"/>
                            <ellipse cx="1102.1" cy="739.8" rx="1.9" ry="1.2"/>
                            <ellipse cx="1216.2" cy="704" rx="1.9" ry="1.2"/>
                            <ellipse cx="1216.3" cy="749.1" rx="1.9" ry="1.2"/>
                            <ellipse cx="1209.4" cy="759.6" rx="1.9" ry="1.2"/>
                            <ellipse cx="1203.1" cy="745.9" rx="1.9" ry="1.2"/>
                            <ellipse cx="1232.1" cy="747.6" rx="1.9" ry="1.2"/>
                            <ellipse cx="1201.4" cy="698.3" rx="1.9" ry="1.2"/>
                            <ellipse cx="1215" cy="677.7" rx="1.9" ry="1.2"/>
                        </g>
                        <path
                            fill="#375953"
                            d="M1105.3 582.1c-.1 5-.1 10.1-.1 15.1.1 8.6 7.5 15.3 16.4 15.5 3.6.1 7.2.2 10.8.4 8.9.5 16.8-5.6 18-14.2.6-5 1.3-10 2.1-14.9-15.5-1.6-31.3-2.3-47.2-1.9z"
                        />
                        <path
                            fill="#4c766f"
                            d="M1108.1 583.9c-.1 4.4-.1 8.8-.2 13.2 0 2.1.5 4 1.3 5.7 5.9-6.4 12.1-12.7 18.7-18.8-6.5-.1-13.1-.2-19.8-.1z"
                        />
                        <path
                            fill="#375953"
                            d="M1160.7 600.2c-5.6-.8-11.3-1.5-17-2.1.2-1.8.4-3.6.7-5.4 5.8.6 11.6 1.3 17.3 2.1-.3 1.8-.6 3.6-1 5.4z"
                        />
                        <path
                            fill="#375953"
                            d="M1157.9 585l-2.7 16.8c-1.4 9.6 5.5 18.4 15 20.2 3.9.7 7.7 1.5 11.5 2.4 9.4 2.2 19.1-3.1 22-12.4l5.1-16.2c-16.4-4.8-33.5-8.4-50.9-10.8z"
                        />
                        <path
                            fill="#4c766f"
                            d="M1160.7 587.5c-.9 4.9-1.7 9.8-2.5 14.7-.4 2.3-.2 4.5.5 6.6 7.5-6.1 15.3-12 23.6-17.5-7.1-1.4-14.3-2.7-21.6-3.8z"
                        />
                        <path
                            fill="#437c49"
                            d="M1196.6 647.9c-7.1-4.1-10.2 1-10.2 1 5.8-1.7 8.1 3.7 8.1 3.7s-5.9 5-34.4 8.8c-48.8 6.2-76-4.7-86.6-10.5 1.7 3.1 4 5.9 6.9 8.2.1.1.2.1.3.2.2.2.4.3.6.4.4.3.9.6 1.3.9.2.1.3.2.5.3.6.3 1.2.7 1.8 1 .1 0 .2.1.2.1.9.4 1.8.8 2.8 1.2 5 1.8 9.5 3.3 13.5 4.5 6.7.2 14.9.3 24.9.2 44.1-.4 69.5-13 69.5-13 1 5.4-.7 8.5-.7 8.5 5.6-5.7 8.6-11.5 1.5-15.5z"
                        />
                        <path
                            fill="#fff"
                            d="M1206 647.6c-4.5 1.1-5.4 2.1-6.5 6.5-1.1-4.5-2.1-5.4-6.5-6.5 4.5-1.1 5.4-2.1 6.5-6.5 1.1 4.5 2 5.4 6.5 6.5z"
                        />
                    </g>
                </g>
            </svg>
        );
    }
}
