import React from "react";

import {Container} from "@material-ui/core";
import {AdminDataTable} from "./table/AdminDataTable";
import {User} from "../model/User";
import {Controller} from "../services/Networking";

export class MainContent extends React.Component<any, any> {

    title: HTMLHeadingElement;
    content: HTMLDivElement;

    constructor(props: any) {
        super(props);
        this.state = {
            enumeration: "",
            data: []
        };
    }
    render() {
        return (
            <Container maxWidth="lg" style={{marginLeft: 0, marginRight: 0, marginTop: "60px", maxHeight: "calc(100vh - 60px)", overflow: "scroll"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <h5 style={{paddingLeft: "20px"}} ref={ref => this.title = ref}> Loading content... </h5>
                    <AdminDataTable enumeration={this.state.enumeration} rows={this.state?.data}/>
                    <div className={"json-content"} ref={ref => this.content = ref}/>
                </div>
            </Container>
        );
    }

    updateTitle(title: string) {
        this.title.innerHTML =  title;
    }
    update(enumeration: Controller, json: any) {
        this.title.innerHTML = enumeration + " (" + json.length + ")";
        this.setState({
            enumeration: enumeration,
            data: json
        });
    }

}
