import React from "react";
import {TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper} from "@material-ui/core";
import {ConversionUtils} from "../../utils/ConversionUtils";
import { FaCheck } from "react-icons/fa";
import {Controller} from "../../services/Networking";

export class AdminDataTable extends React.Component<any, any> {

    render() {
        return (
            <TableContainer component={Paper} style={{marginBottom: "50px", maxHeight: "100%"}}>
                <Table className={"admin-data-table"}>
                    <TableHead>
                        {this.getTableHead()}
                    </TableHead>
                    <TableBody>
                        {this.getTableBody()}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    getTableHead() {
        return (
            <TableRow>
                {this.getControllerColumns().map((column: string) => (
                    <TableCell>{column}</TableCell>
                ))}
            </TableRow>
        );
    }

    getControllerColumns(): any[] {
        switch (this.props.enumeration) {
            case Controller.User:
                return ["Id", "Name", "Email", "Company", "Address", "Is Caterer?", "Is Admin?"];
            case Controller.Event:
                return ["Id", "CreatorId", "Date", "Location", "Budget", "Category", "Cuisine",
                    "Drink", "Event", "Other", "Rentals", "Seating", "Service"];
            case Controller.Offer:
                return ["Id", "EventId", "CatererId", "Date", "Menu Items", "Accepted?", "Rejected?"];
            case Controller.Invoice:
                return ["Id", "CatererId", "CustomerId", "OfferId", "Create Date" , "Due Date", "Is Paid?"];
            case Controller.Menu:
                return ["Id", "CategoryId", "CatererId", "Name", "Description"];
            case Controller.Feedback:
                return ["Id", "UserId", "Message"];
            case Controller.Message:
                return ["Id", "CatererId", "CustomerId", "OfferId", "text", "timestamp"];
            case Controller.Rating:
                return ["Id", "OfferId", "RaterId", "RecipientId", "Score", "Comment"];
            default:
                return [];
        }
    }

    getTableBody() {
        if (!this.props.rows || this.props.rows.length === 0) {
            return;
        }

        switch (this.props.enumeration) {
            case Controller.User:
                return this.mapTableRow(["id", "name", "email", "companyName", "address", "isCaterer", "isAdmin"]);
            case Controller.Event:
                return this.mapTableRow([
                        "id", "creatorId", "date", "location", "budget", "multi_category", "multi_cuisine",
                        "multi_drink", "multi_event", "multi_other", "multi_rentals", "multi_seating", "multi_service"
                    ]);
            case Controller.Offer:
                return this.mapTableRow(["id", "eventId", "catererId", "createDate", "menuItems", "accepted", "rejected"]);
            case Controller.Invoice:
                return this.mapTableRow(["id", "catererId", "customerId", "offerId", "createDate", "dueDate", "isPaid"]);
            case Controller.Menu:
                return this.mapTableRow(["id", "categoryId", "catererId", "name", "description"]);
            case Controller.Feedback:
                return this.mapTableRow(["id", "userId", "message"]);
            case Controller.Message:
                return this.mapTableRow(["id", "catererId", "customerId", "offerId", "text", "timestamp"]);
            case Controller.Rating:
                return this.mapTableRow(["id", "offerId", "raterId", "recipientId", "score", "comment"]);
            default:
                return null;
        }
    }

    mapTableRow(keys: string[]) {
        return this.props.rows.map((row: any) => (
            <TableRow key={row.id.toString()}>
                {keys.map((key: string) => (<TableCell>{row[key]}</TableCell>))}
            </TableRow>
        ));
    }

    getCheckedValue(value: any) {
        const bool = ConversionUtils.toBoolean(value);
        if (bool) {
            return <FaCheck/>
        }
        return "";
    }
}
