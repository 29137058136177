import React from "react";
// @ts-ignore
import ReactModalLogin from "react-modal-login";
import {Networking} from "../services/Networking";
import {LocalStorage} from "../services/LocalStorage";

export class LoginModalProps {
    onLoginSuccess: any;
}
export class LoginModalState {
    showModal: boolean;
    loading: boolean;
    recoverPasswordSuccess: boolean;
    error: any;
}

export class LoginModal extends React.Component<LoginModalProps, LoginModalState> {

    content: HTMLDivElement;

    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
            loading: false,
            recoverPasswordSuccess: false,
            error: null
        };
    }

    facebookConfig = {
        appId: "YOUR FB APP ID GOES HERE",
        cookie: true,
        xfbml: true,
        version: "v3.2",
        scope: "email"
    };
    googleConfig = {
        client_id: "YOUR_CLIENT_ID.apps.googleusercontent.com",
        scope: "profile email"
    };

    /**
     * https://github.com/thebeaverhead/react-modal-login
     */
    render() {
        return (
            <ReactModalLogin
                visible={this.state.showModal}
                onCloseModal={this.closeModal.bind(this)}
                loading={this.state.loading}
                error={this.state.error}
                tabs={{
                    afterChange: this.afterTabsChange.bind(this)
                }}
                loginError={this.labelWithTitle("Couldn't sign in, please try again.")}
                registerError={this.labelWithTitle("Couldn't sign up, please try again.")}
                startLoading={this.startLoading.bind(this)}
                finishLoading={this.finishLoading.bind(this)}
                form={{
                    onLogin: this.onLogin.bind(this),
                    onRegister: this.onRegister.bind(this),
                    onRecoverPassword: this.onRecoverPassword.bind(this),
                    recoverPasswordSuccessLabel: this.isRecoverySuccessful(),
                    recoverPasswordAnchor: this.labelWithTitle("Forgot your password?"),
                    loginBtn: this.labelWithTitle("Sign in"),
                    registerBtn: this.labelWithTitle("Sign up"),
                    recoverPasswordBtn: this.labelWithTitle("Send new password"),
                    loginInputs: this.loginInputs(),
                    registerInputs: this.registerInputs(),
                    recoverPasswordInputs: this.recoverInputs(),
                }}
            />
        );
    }

    async onLogin(e: any) {
        const email = this.getInputElement("email").value;
        const password = this.getInputElement("password").value;
        if (!email || !password) {
            this.setState({
                error: true
            });
        } else {
            console.log(email, password);
            const result = await Networking.instance.logIn(email, password);
            if (!result.error) {
                LocalStorage.instance.setSessionToken(result.sessionId);
                Networking.instance.refreshToken();
                this.closeModal(null);
                this.props.onLoginSuccess(result);
            } else {
                this.setState({
                    error: true
                });
            }
        }
    }

    getInputElement(id: string): HTMLInputElement {
        return document.querySelector('#' + id) as HTMLInputElement;
    }

    onRegister(e: any) {

    }

    onRecoverPassword(e: any) {

    }

    closeModal(e: any) {
        this.setState({
            showModal: false,
            error: null
        });
    }

    afterTabsChange(e: any) {
        this.setState({
            error: null
        });
    }

    startLoading(e: any) {
        this.setState({
            loading: true
        });
    }

    finishLoading(e: any) {
        this.setState({
            loading: false
        });
    }
    onLoginSuccess(e: any) {

    }
    onLoginFail(e: any) {

    }

    labelWithTitle(title: string) {
        return { label: title };
    }

    isRecoverySuccessful() {
        return this.state.recoverPasswordSuccess ? { label: "New password has been sent to your mailbox!" } : null;
    }

    loginInputs() {
        return  [
            {
                containerClass: 'RML-form-group',
                label: 'Email',
                type: 'email',
                inputClass: 'RML-form-control',
                id: 'email',
                name: 'email',
                placeholder: 'Email',
            },
            {
                containerClass: 'RML-form-group',
                label: 'Password',
                type: 'password',
                inputClass: 'RML-form-control',
                id: 'password',
                name: 'password',
                placeholder: 'Password',
            }
        ]
    }

    registerInputs() {
        return [
            {
                containerClass: 'RML-form-group',
                label: 'Nickname',
                type: 'text',
                inputClass: 'RML-form-control',
                id: 'login',
                name: 'login',
                placeholder: 'Nickname',
            },
            {
                containerClass: 'RML-form-group',
                label: 'Email',
                type: 'email',
                inputClass: 'RML-form-control',
                id: 'email',
                name: 'email',
                placeholder: 'Email',
            },
            {
                containerClass: 'RML-form-group',
                label: 'Password',
                type: 'password',
                inputClass: 'RML-form-control',
                id: 'password',
                name: 'password',
                placeholder: 'Password',
            }
        ];
    }

    recoverInputs() {
        return [
            {
                containerClass: 'RML-form-group',
                label: 'Email',
                type: 'email',
                inputClass: 'RML-form-control',
                id: 'email',
                name: 'email',
                placeholder: 'Email',
            },
        ];
    }
}
