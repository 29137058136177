import React from "react";

import {Button} from "@material-ui/core";

export class NavigationBarProps {
    onClick: any;
    text: string | null;
}

export class NavigationBar extends React.Component<NavigationBarProps, any> {

    content: HTMLDivElement;

    render() {
        return (
            <div className={"navigation-bar"}>
                <div style={{float: "right"}}>
                    <Button style={{color: "white", marginRight: "10px"}} onClick={this.props.onClick}>
                        {this.props.text}
                    </Button>
                </div>
            </div>
        );
    }

    update(text: string) {
        this.content.innerHTML = text;
    }

}
