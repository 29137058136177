
export class ConversionUtils {

    public static toBoolean(value: any) {
        if (value === 1) {
            return true;
        }
        return false;
    }
}
