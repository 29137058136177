import React from 'react';

import 'react-pro-sidebar/dist/css/styles.css';
import './App.css';

import {Controller, Networking} from "./services/Networking";
import {Sidebar} from "./view/Sidebar";
import {MainContent} from "./view/MainContent";
import {NavigationBar} from "./view/NavigationBar";
import {LoginModal} from "./view/LoginModal";
import {LocalStorage} from "./services/LocalStorage";

export default class App extends React.Component<any, any> {

    content: MainContent | null;
    loginModal: LoginModal | null;
    sidebar: Sidebar | null;

    data: {};

    constructor(props: any) {
        super(props);
        this.state = {
            collapsed: false,
            username: undefined
        };
        this.data = {};
    }

    async componentDidMount() {
        if (LocalStorage.instance.isLoggedIn()) {
            const result = await Networking.instance.getUserInfo();

            if (result.error) {
                this.updateTitle("🤔 Something went wrong, please log in again...");
                LocalStorage.instance.deleteSessionToken();
                return;
            }
            this.setState({
                username: result.name
            });

            await this.requestContent();
            const tab = this.sidebar.findActiveTab();
            if (tab) {
                this.updateContent(tab);
            } else {
                this.updateTitle("Click on a tab to load its content...");
            }
        } else {
            this.updateTitle("Log in to continue...");
        }
    }

    private async requestContent() {
        for (const item in Controller) {
            const enumeration = item as Controller;
            const response = await Networking.instance.fetch(enumeration);
            const json = await response.json();
            // @ts-ignore
            this.data[enumeration.toLocaleLowerCase()] = json;
        }
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <div>
                        <NavigationBar onClick={this.onNavigationBarClick.bind(this)} text={this.state?.username ? this.state.username : "LOG IN"}/>
                        <div style={{display: "flex"}}>
                            <Sidebar onClick={this.onSidebarButtonClick.bind(this)} ref={ref => this.sidebar = ref }/>
                            <MainContent ref={ref => {
                                this.content = ref;
                            }}/>
                        </div>
                    </div>


                </header>
                <LoginModal ref={ref => {
                    this.loginModal = ref;
                }} onLoginSuccess={this.onLoginSuccess.bind(this)}/>
            </div>
        );
    }

    async onLoginSuccess(e: any) {
        await this.requestContent();
        this.updateContent(this.sidebar.findActiveTab(), false);
        this.setState({ username: e.name });
    }
    onSidebarButtonClick(e: any) {
        const enumeration = e as Controller;
        this.updateContent(enumeration);
    }
    updateTitle(title: string) {
        this.content.updateTitle(title);
    }
    updateContent(enumeration: Controller, forceUpdate = true) {
        if (!enumeration) {
            this.updateTitle("Click on a tab to load its content...");
            return;
        }
        // @ts-ignore
        const data = this.data[enumeration][this.findResponseContentKey(enumeration)];
        this.content.update(enumeration, data);
        if (forceUpdate) {
            this.forceUpdate();
        }
    }
    async onNavigationBarClick(e: any) {
        if (LocalStorage.instance.isLoggedIn()) {
            return;
        }
        this.loginModal.setState({
            showModal: true
        });
    }

    findResponseContentKey(enumeration: Controller): string {
        if (enumeration === Controller.Feedback) {
            return enumeration;
        }
        if (enumeration === Controller.Menu) {
            return "items";
        }

        return enumeration + "s";
    }
}

