import React from "react";
import {Menu, MenuItem, ProSidebar, SidebarHeader} from "react-pro-sidebar/dist";
import MainIcon from "../images/MainIcon";
import {HashRouter, Link} from "react-router-dom";
import {
    FaBirthdayCake,
    FaCoffee,
    FaCoins,
    FaFacebookMessenger,
    FaFileInvoice,
    FaSwatchbook,
    FaTrophy,
    FaUserAlt
} from "react-icons/fa";
import {Controller} from "../services/Networking";
import {StringUtils} from "../utils/StringUtils";

export class SidebarProps {
    onClick: any;
}
export class Sidebar extends React.Component<SidebarProps, any> {

    constructor(props: SidebarProps) {
        super(props);
        this.state = {
            collapsed: false
        };
    }
    render() {
        return (
            <ProSidebar collapsed={this.state.collapsed}>
                <SidebarHeader>
                    <div className={"sidebar-header-container"} onClick={(e: any) => {
                        this.setState({collapsed: !this.state.collapsed})
                    }}>
                        <MainIcon/>
                    </div>
                </SidebarHeader>
                <HashRouter>
                    <Menu iconShape="square">
                        {this.createMenuItem(<FaUserAlt/>, Controller.User)}
                        {this.createMenuItem(<FaBirthdayCake/>, Controller.Event)}
                        {this.createMenuItem(<FaCoffee/>, Controller.Offer)}
                        {this.createMenuItem(<FaFileInvoice/>, Controller.Invoice)}
                        {this.createMenuItem(<FaSwatchbook/>, Controller.Menu)}
                        {this.createMenuItem(<FaCoins/>, Controller.Feedback)}
                        {this.createMenuItem(<FaFacebookMessenger/>, Controller.Message)}
                        {this.createMenuItem(<FaTrophy/>, Controller.Rating)}
                    </Menu>
                </HashRouter>
            </ProSidebar>)
    }

    findActiveTab(): Controller {
        const tab = window.location.href.split("/#/")[1];
        if (!tab) {
            return undefined;
        }
        return tab as Controller;
    }
    createMenuItem(icon: any, enumeration: Controller) {
        return <MenuItem icon={icon}>{StringUtils.capitalize(enumeration)}<Link to={"/" + enumeration} onClick={() => {
            this.props.onClick(enumeration);
        }}/></MenuItem>
    }
}
