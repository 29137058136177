import {LocalStorage} from "./LocalStorage";

export enum Controller {
    User = "user",
    Event = "event",
    Offer = "offer",
    Invoice = "invoice",
    Menu = "menu",
    Feedback = "feedback",
    Message = "message",
    Rating = "rating"
}
export class Networking {

    public static instance = new Networking();

    BASE_PATH = "https://api.allicater.com/";
    FUNCTION = "list";

    sessionId = LocalStorage.instance.getSessionToken();

    public async fetch(controller: Controller) {
        return await fetch(this.getUrl(controller, this.FUNCTION), { headers: this.getDefaultHeaders() });
    }

    public async logIn(email: string, password: string) {
        const url = this.getUrl(Controller.User, "log-in");

        const response = await fetch(url, {
            method: "POST",
            body: new URLSearchParams({"email": email, "password": password}),
            headers: this.getDefaultHeaders()
        });
        return await response.json();
    }

    public async getUserInfo() {
        const url = this.getUrl(Controller.User, "me");
        const response = await fetch(url, { headers: this.getDefaultHeaders() });
        return await response.json();
    }

    private getDefaultHeaders() {
        const result = new Headers();
        result.set("Content-Type", "application/x-www-form-urlencoded");
        result.set(LocalStorage.instance.TOKEN_KEY, this.sessionId);
        return result;
    }
    private getUrl(enumeration: Controller, endpoint: string): string {
        return this.BASE_PATH + enumeration + "/" + endpoint;
    }

    public refreshToken() {
        this.sessionId = LocalStorage.instance.getSessionToken();
    }
}
